.modal-danger .modal-content{
    background-color: #fff; /* Задайте цвет фона для модального окна */
    box-shadow: 0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.025);
    padding: 20px;
}

/*.btn-simple {*/
/*    color: #fff; !* Задайте цвет текста для кнопок *!*/
/*    background-color: #f44336; !* Задайте цвет фона для кнопок *!*/
/*    border: none; !* Уберите границу кнопок *!*/
/*}*/
/**/
/*.btn-simple:hover {*/
/*    background-color: #d32f2f; !* Задайте цвет фона для кнопок при наведении *!*/
/*}*/
