@media (max-width: 1024px) {
    .hideXS {
        display: none !important;
    }
    .d-flexXS {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem
    }
    .d-flexXS.mt-2 {
        margin-top: 0 !important;
    }
    .fdcXS {
        flex-direction: column;
    }

    .tacXS {
        width: 100%;
        text-align: center;
    }
    .ml-3XS {
        margin-left: 0 !important;
    }
    .align-items-end {
        align-items: start !important;
    }
    .aieXS {
        align-items: end !important;
    }
    .w100pXS {
        width: 100% !important;
    }
    .selectFilters {
        margin-bottom: 1em;
    }
    thead {
        display: none;
    }

    tbody tr {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }
    .white-content tbody tr {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }
    tbody tr td{
        width: 100% !important;
        max-width: none !important;
    }
    tbody tr td.text-right,
    tbody tr td.text-left{
        text-align: center !important;
    }
    .btXS {
        border-top: 2px solid #fff;
        padding-top: 2em;
    }
    .white-content .btXS {
        border-top: 2px solid #000;
    }
}
