.badge-success {
    background-color: #19AB27;
}
.table-responsive {
    overflow-y: hidden !important;
    overflow-x: auto !important;
}

.custom-checkbox input {
    position: static;
    margin: 0 10px 0 0 ;
}

.dealsForm .toggle-switch {
    display: flex;
    align-items: center;
}

.dealsForm .toggle-icon {
    font-size: 1.2em; /* Измените размер иконок */
    color: gray; /* Цвет иконок в "выключенном" состоянии */
}

.dealsForm .toggle-icon.active {
    color: #0f0; /* Цвет иконок в "включенном" состоянии */
}

.dealsForm .react-toggle-track {
    background-color: gray !important; /* Фон трека в "выключенном" состоянии */
}

.dealsForm .react-toggle-thumb {
    box-shadow: none !important; /* Убрать тень с ползунка */
}

.white-content .text-white:not(.lead) {
    color: rgba(34, 42, 66, 0.7) !important;
}

