/* errorAlert.css */
.error-alert {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translate(-50%);
    width: 70%;
    z-index: 1052;
}
.alert-enter {
    opacity: 0;
}

.alert-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
